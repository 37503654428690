.row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

/* Color slider  */

.c-1-color {
    background: red;
}
.c-2-color {
    background: yellow;
}
.c-3-color {
    background: green;
}
.c-4-color {
    background: blue;
}
.c-5-color {
    background: purple;
}

.noUi-handle::before,
.noUi-handle::after {
    display: none;
}

#slider-round {
    height: 10px;
}

/* #slider-round .noUi-connect {
    background: #c0392b;
} */

#slider-round .noUi-handle {
    height: 18px;
    width: 18px;
    top: -5px;
    right: -9px;
    border-radius: 9px;
}

.noUi-tooltip {
    display: none;
}
.noUi-active .noUi-tooltip {
    display: block;
}

#slider-round .noUi-handle {
    background: url(../src/icon/trig.png) 50% 50% no-repeat;
    background-size: contain;
    width: 22px;
    height: 28px;
    top: -17px;
    box-shadow: none;
    border: medium;
    right: -11px;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    cursor: default;
    color: #ffffff !important;
    border: 1px solid transparent;
    background: transparent;
    box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    color: #ffffff !important;
    border: 1px solid transparent;
    border-radius: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #333 !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background: linear-gradient(to bottom, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
}

.eventRemoverInput{
    width: 100%;
    outline: 0;
    border-radius: 0.25rem;
    border: 0;
    padding-left: 8px;
    padding-bottom: 2px;
}